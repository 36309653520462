import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		maxWidth: 600,
		margin: 'auto',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		flexWrap: 'wrap',
	},
	base: {
		margin: '10px',
	},
	dropdown: {
		width: '150px',
	},
});
