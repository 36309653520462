import {
	Button,
	FormControl,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

import { useStyles } from './styles';

const MILES_TO_KILOMETRES = 1.609;

const App: React.FC = () => {
	const classes = useStyles();

	const defaultPaceUnit = 'km';
	const defaultDistanceUnit = 'km';
	const defaultTime = '';
	const defaultPace = '';
	const defaultDistance = '';
	const timePlaceholder = '00:00:00';
	const pacePlaceholder = '00:00:00';
	const distancePlaceholder = '00.00';

	const [distance, setDistance] = useState(defaultDistance);
	const [time, setTime] = useState(defaultTime);
	const [pace, setPace] = useState(defaultPace);
	const [paceUnit, setPaceUnit] = useState(defaultPaceUnit);
	const [distanceUnit, setDistanceUnit] = useState(defaultDistanceUnit);

	const handleDistanceChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setDistance(event.target.value);
	};

	const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTime(event.target.value);
	};

	const handlePaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPace(event.target.value);
	};

	const handleDistanceUnitChange = (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		setDistanceUnit(event.target.value as string);
	};

	const handlePaceUnitChange = (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => {
		setPaceUnit(event.target.value as string);
	};

	const handlePaceCalculateClick = () => {
		if (
			distance === defaultDistance &&
			time === defaultTime &&
			pace === defaultPace
		) {
			return;
		} else if (distance !== defaultDistance && time !== defaultTime) {
			const paceInSeconds =
				formatTime(time) / Number(distance) / getMultiplier();
			setPace(formatDisplayPace(paceInSeconds));
		}
	};

	const handleTimeCalculateClick = () => {
		if (
			distance === defaultDistance &&
			time === defaultTime &&
			pace === defaultPace
		) {
			return;
		} else if (distance !== defaultDistance && pace !== defaultPace) {
			const timeInSeconds =
				formatTime(pace) * Number(distance) * getMultiplier();
			setTime(formatDisplayTime(timeInSeconds));
		}
	};

	const handleDistanceCalculateClick = () => {
		if (
			distance === defaultDistance &&
			time === defaultTime &&
			pace === defaultPace
		) {
			return;
		} else if (time !== defaultTime && pace !== defaultPace) {
			const distance =
				formatTime(time) / formatTime(pace) / getMultiplier();
			setDistance(formatDisplayDistance(distance));
		}
	};

	const handleResetClick = () => {
		setDistance(defaultDistance);
		setTime(defaultTime);
		setPace(defaultPace);
		setDistanceUnit(defaultDistanceUnit);
		setPaceUnit(defaultPaceUnit);
	};

	const formatTime = (time: string) => {
		const splitTime = time.split(':');
		if (splitTime.length === 3) {
			return (
				Number(splitTime[0]) * 60 * 60 +
				Number(splitTime[1]) * 60 +
				Number(splitTime[2])
			);
		} else if (splitTime.length === 2) {
			return Number(splitTime[0]) * 60 + Number(splitTime[1]);
		} else {
			return Number(splitTime[0]);
		}
	};

	const formatDisplayTime = (timeInSeconds: number) => {
		const hours = Math.floor(timeInSeconds / 60 / 60);
		const minutes = Math.floor(timeInSeconds / 60) - hours * 60;
		const seconds =
			Math.floor(timeInSeconds) - hours * 60 * 60 - minutes * 60;
		return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
	};

	const formatDisplayPace = (paceInSeconds: number) => {
		const hours = Math.floor(paceInSeconds / 60 / 60);
		const minutes = Math.floor(paceInSeconds / 60) - hours * 60;
		const seconds =
			Math.floor(paceInSeconds) - hours * 60 * 60 - minutes * 60;
		return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
	};

	const formatDisplayDistance = (distance: number) => {
		const formatted = (Math.round(distance * 100) / 100).toFixed(2);
		const splitDistance = String(formatted).split('.');
		if (splitDistance[0].length === 1) {
			splitDistance[0] = '0' + splitDistance[0];
		}
		return String(splitDistance.join('.'));
	};

	const pad = (value: number) => {
		return value < 10 ? '0' + value : value;
	};

	const getMultiplier = () => {
		if (distanceUnit === 'km' && paceUnit === 'mi') {
			return 1 / MILES_TO_KILOMETRES;
		} else if (distanceUnit === 'mi' && paceUnit === 'km') {
			return MILES_TO_KILOMETRES;
		} else {
			return 1;
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.row}>
				<TextField
					className={classes.base}
					variant="outlined"
					label="Distance"
					onChange={handleDistanceChange}
					value={distance}
					placeholder={distancePlaceholder}
				/>
				<FormControl className={`${classes.base} ${classes.dropdown}`}>
					<Select
						value={distanceUnit}
						onChange={handleDistanceUnitChange}
					>
						<MenuItem value={'km'}>Kilometres</MenuItem>
						<MenuItem value={'mi'}>Miles</MenuItem>
					</Select>
				</FormControl>
				<Button
					className={classes.base}
					variant="contained"
					onClick={handleDistanceCalculateClick}
				>
					Calculate
				</Button>
			</div>
			<div className={classes.row}>
				<TextField
					className={classes.base}
					variant="outlined"
					label="Time"
					onChange={handleTimeChange}
					value={time}
					placeholder={timePlaceholder}
				/>
				<Button
					className={classes.base}
					variant="contained"
					onClick={handleTimeCalculateClick}
				>
					Calculate
				</Button>
			</div>
			<div className={classes.row}>
				<TextField
					className={classes.base}
					variant="outlined"
					label="Pace"
					onChange={handlePaceChange}
					value={pace}
					placeholder={pacePlaceholder}
				/>
				<FormControl className={`${classes.base} ${classes.dropdown}`}>
					<Select value={paceUnit} onChange={handlePaceUnitChange}>
						<MenuItem value={'km'}>Per Kilometre</MenuItem>
						<MenuItem value={'mi'}>Per Mile</MenuItem>
					</Select>
				</FormControl>
				<Button
					className={classes.base}
					variant="contained"
					onClick={handlePaceCalculateClick}
				>
					Calculate
				</Button>
			</div>
			<Button
				className={classes.base}
				variant="contained"
				onClick={handleResetClick}
			>
				Reset
			</Button>
		</div>
	);
};

export default App;
